import React from 'react';
const { Component } = require("react");


class Contact extends Component {
	render() {

		
		return (
			<div class="main">
				<h1 className="home-header">Contact Allison</h1>
				
			</div>		
		);
}
}


export default Contact;
