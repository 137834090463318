import React from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
const { Component } = require("react");


class Nav extends Component {
	render() {

		return (
			<nav>
				<a href="index.html"><img src="images/logo-working.png" className="logo" alt="Allison Guthrie, UX Designer"/></a>
				<ul className="nav flex-column">
				<Link to="/">
				<li className="nav-item nav-link">Home</li>
				</Link>
				<Link to="/about">
				<li className="nav-item nav-link">About</li>
				</Link>
				<Link to="/portfolio">
				<li className="nav-item nav-link">Portfolio</li>
				</Link>
				<Link to="/process">
				<li className="nav-item nav-link">Process</li>
				</Link>
				<Link to="/contact">
				<li className="nav-item nav-link">Contact</li>
				</Link>
				</ul>
			</nav>
			
		)
	}
}


export default Nav;
