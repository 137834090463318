import React from 'react';
const { Component } = require("react");


class Home extends Component {
	render() {

		
		return (
			<div class="main">
				<h1 className="home-header">intuitive user interface | thoughtful digital design | engaging content | squeaky clean code</h1>
				<div className="text-wrap-home-page">
				<h2>Languages & Frameworks</h2>
				<ul className="langs">
					<li>HTML</li>
					<li>CSS</li>
					<li>JavaScript/JQuery</li>
					<li>PHP</li>
				</ul>
				<h2>Applications</h2>
				<ul className="langs">
					<li>Adobe CC for design</li>
					<li>VS Code as my IDE of choice</li>
					<li>Miro for all creative mapping</li>
					<li>Figma for wireframing and prototyping</li>
					<li>Lookback for usability testing</li>
					<li>Hotjar for heatmapping</li>	
				</ul>
				
				</div>
			</div>		
		);
}
}


export default Home;
