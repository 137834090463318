import React from 'react';
import ReactDOM from 'react-dom';
import Nav from '../src/components/Nav.js';
import Home from '../src/components/Home.js';
import About from '../src/components/About.js';
import Portfolio from '../src/components/Portfolio.js';
import Process from '../src/components/Process.js';
import Contact from '../src/components/Contact.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


class App extends React.Component {
	render() {

		return (
			<div className="App">
				<Router>
			  	<body>
					<div className="container-fluid">
						
						<div className="row">
							<div className="col-md-4 sidebar">
								<Nav />
							</div>
							<div className="col-md-8">
								<Switch>
									<Route path='/' exact component={Home} />
									<Route path='/about' component={About} />
									<Route path='/portfolio' component={Portfolio} />
									<Route path='/process' component={Process} />
									<Route path='/contact' component={Contact} />
								</Switch>
							
								
								<img src="images/headshot.jpg" className="headshot" alt="Allison Guthrie headshot"/>
							</div>
						</div>
					</div>		
				</body>
				</Router>
			</div>
		  );
	}

}

export default App;
