import React from 'react';
const { Component } = require("react");


class About extends Component {
	render() {

		
		return (
			<div class="main">
				<h1 className="home-header">About Allison</h1>
				
			</div>		
		);
}
}


export default About;
